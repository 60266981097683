import { Edit3, MessageCircle, Eye, Rss, AtSign } from 'react-feather';
import './../App.css'
import '../index.css';

export const Footer = () => {
    return <div class="footer">
        <div class="footer-section">
            <span class="footer-section-title"><Rss size={16}/>כתבות אחרונות</span>
            <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>בקרוב</span>
        </div>
        <div class="footer-section">
            <span class="footer-section-title"><Eye size={16}/>הנקראות ביותר</span>
            <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>בקרוב</span>
        </div>
        <div class="footer-section">
            <span class="footer-section-title"><MessageCircle size={16}/>תגובות</span>
            <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>בקרוב</span>
            {/* <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>אולי לפעמים עדיף שכן</span>
            <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>אולי לפעמים עדיף שלא</span>
            <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>אולי לפעמים עדיף שכן</span>
            <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>אולי לפעמים עדיף שלא</span>
            <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>אולי לפעמים עדיף שכן</span>
            <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>אולי לפעמים עדיף שלא</span>
            <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>אולי לפעמים עדיף שכן</span> */}
        </div>
        <div class="footer-section">
            <span class="footer-section-title"><AtSign size={16}/>עלינו</span>
            <span class="footer-title"> <i data-feather="edit-3" height="16px"></i>בקרוב</span>
            <span class="footer-title"></span>
        </div>
    </div>;
}