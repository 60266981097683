import { useNavigate } from 'react-router-dom';
import logo from '../assets/photos/tattoo.png';
import { User, AlignRight, Paperclip, Video, Link, Smile, Send } from 'react-feather';
import './../App.css'
import '../index.css';

export const Header = () => {
    const navigate = useNavigate();
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    return <><div class="header-title">
        {/* <span className='time'><DateTimeComponent /></span> */}
        <span>אחרת</span>
        <span>קצת</span>
        <img src={logo} alt="" />
    </div>
        {(!mediaQuery.matches) &&
            (<div class="header">
                <span onClick={() => navigate(`/`)} ><User />בית</span>
                <span><AlignRight />פוסטים</span>
                <span><Paperclip />מומלצים</span>
                <span><Video />סרטונים</span>
                <span><Link />קישורים</span>
                <span><Smile />זה ליופי</span>
                <span><Send />דברו אליי</span>
            </div>)}
    </>
}