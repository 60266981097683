import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate, Outlet } from 'react-router-dom';
import { Footer } from './components/footer';
import DateTimeComponent from './components/DateTime';
import { Home } from './components/home';
import { Article } from './components/article';
import { Header } from './components/header';
import './App.css'
import './index.css';
import flower from './assets/photos/flower.png'
function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="article/:id" element={<Article />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
export const articlesObject = {
  msb010722: {
    title: () => (
      <span class="title">
        מעל
        <span class="title-p"> לכל הַמְּצֻפָּה </span>
      </span>
    ),
    subtitle: 'מה היה, מה נותר ולאן ממשיכים מכאן ?',
    user: 'אנונימי',
    pages: '8 דפים',
    date: '24/12/23',
    content: () => (
      <p className='story'>
        שנה, שנה וקצת, שנה וקצת יותר חלפה.
        שנה שלמה וקצת יותר שכל יום כחלק ממנה, לווה בגעגועים אליה ובלפחות מחשבה אחת עליה, שהובילה ללפחות לרגש אחד אליה, שהורגש היטב בכל יום ביומו. <br></br>
        ניסיתי להבין; בחיי, בכל היקר והזול לי, ניסיתי להבין איך דבר כה עמוק, מתפרק בשיח כל כך רדוד ואינו תואם לרגש שחווינו ביחד, ולא לעתיד ששנינו ציפינו לו.<br></br>
        אינני אידאליזטור ומעולם גם לא הייתי, אני מבין ומכיר את הבעיות והקשרים שהיוו חלק בלתי נפרד ממערכת היחסים ביננו. יש מנהרות שאין להם אור בקצה, אני יודע, זאת פשוט לא הייתה אחת כזאת.<br></br>
        אבל בתאכלס?, הבעיות היו פתירות, והקשרים היו פירמים. אז למה? למה זה נגמר כך? למה לא נלחמנו? למה בעצם ויתרנו?<br></br>
        לשמחתי וגם קצת לצערי אני זוכר הכל, את השיח הראשון שלנו (ואיך בקלות כה רבה היא הצליחה להקסים ולהצחיק אותי כבר אז), אילו בגדים נלבשו על ידה בדייט הראשון, ומה בדיוק קרה בדייט השני, השלישי, הרביעי והחמישי.<br></br>
        אני זוכר כל מפגש ביננו, אני זוכר את הרגע שהוגדרנו על ידינו כ"זוג", את ההודעה הפחדנית שלי, שנבעה מחוסר ביטחון ולוותה ברצון טוב להגדרת גבולות הקשר. וגם את שיחת הטלפון המקסימה שלה שהגיעה שנייה וחצי אחרי.<br></br>
        לא הספקתי לראות את צבעם של שני סימני הניצחון הכחולים על מסך הטלפון, וכבר קיבלתי שיחה, שיחה  שמטרתה הייתה להבהיר לי בדיוק היכן עומדות גבולות הגזרה הללו בגינם נשלחה ההודעה מלכתחילה.<br></br>
        חשוב שבקשר יהיה לפחות צד אחד אמיץ כי אמיצות מדבקת.<br></br>
        אני זוכר את ההתרגשות הקטנה בלב שלי לפני כל מפגש איתה. איך הוא אהב אותה, איך הוא אהב להתרגש כל פעם מחדש ממנה.<br></br>
        אני זוכר כל ארוחה שידייה הכינה לנו, את כל הנסיעות, את כל החיוכים, ובגדול? אני פשוט זוכר הכל. <br></br>
        <br></br><span className='page'>עמוד 1</span><br></br><br></br>
        זה מוזר, כמה ניתן להיקשר במשך ארבעה חודשים לבת אדם אחת קטנה ומתוקה? מסתבר שעד הירח ובחזרה לפחות פעמיים ביום חלש. אני רוצה להאמין במלוא ליבי שלא הייתי עבורה עוד קשר קצר שנגמר באופן רע. עבורי, היא הייתה הכל,  היא הייתה "האחת".<br></br>
        לא רק ה"אחת שלי", אין פה שום קשר אליי, אלא אליה. היא לכשעצמה הייתה כל כך מעבר לכל מה שהכרתי לפני כן. חיפשתי מילים מתאימות לתאר אותה כשנשאלתי עליה בזמנו, אבל כשלתי ונכשלתי, כל מילת פאר והלל שעלתה בראשי, נגנזה בשנייה, כי איך ניתן לתאר את זאת שמעל לכל המצופה?<br></br>
        היא לא הייתה מדהימה, ולא הייתה מקסימה, וגם לא הייתה חכמה או יפה, או בעלת לב טוב. היא הייתה ועדיין מעבר למשמעות וההגדרה הבנאלית של המילים האלו בהפרש.<br></br>
        היא הייתה ותמיד תישאר מיוחדת במינה, שכן הקסם האמיתי שלה שלא נבע ממעלותיה הרבות אלא מההשפעה המינורית של חוסר השלמות שלה.<br></br>
        <br></br><br></br>
        זה יכול להיקרא מוזר, ואולי לא, אבל מהשנייה הראשונה הרגשתי במשהו אחר, במשהו שונה, תחושה ורגש כה זרים ושונים שפקדו אותי בשלב מוקדם באופן לא צפוי.<br></br>
        אחרי שנה שהיא לא כאן, אני יכול לדעת בוודאות, שבדיוק הרגשות האלו הם שהובילו לתוצאה של פחד עמוק שליווה אותי במהלך כל הקשר. לא הפחד ש"זה-זה" ושזה לנצח, ושכל בוקר מעתה ועד עולם, אתעורר אל מול מראה פניה היפות.<br></br>
        נהפוכו, פשוט פחדתי שעבורה זה לא.<br></br>
        אני לא מפחד מדברים רבים ואולי דווקא חבל, אולי אם הייתי טיפוס פחדן, הייתי בעל ניסיון עשיר בהתמודדות עם פחדים. הפחד שיום אחד היא יכולה לקום, להתלבש, ללכת ולא לחזור, פגש בי. ולא בגלל שהיא מסוגלת לעשות זאת לפתע פתאום, אלא בגלל פסי האישיות שבי שיכולים בצורה ישירה להוביל אותה לעשות כך.<br></br>
        <br></br><span className='page'>עמוד 2</span><br></br><br></br>
        אז ככה תזכורת קצרצרה ומתומצתת לשיחתנו האחרונה:<br></br>
        "לא הצלחתי להוציא מילה, נראה על פניו שהדמעות על פניי לא הזיזו תו של חמלה בפנייה. לולא היינו מקיים את השיחה הזאת במרחב הטלפוני, הייתי יכול לדעת בבטחה. אני מתקשה קצת בקריאת תווי פנים בטלפון.<br></br>
        רצף של שצף זועם ומוצדק, ו"ביי" אחד שלא השאיר אחריו רווח כמעט לכלום, שלווה בניתוק הכי כואב שזכור לי.<br></br>
        היא הבהירה לי בכמה משפטים קצרים המון דברים גדולים.<br></br>
        -שאחרי השיחה האחרונה שלנו היא לא רוצה שום קשר אליי,<br></br>
        -שנחצו קווים,<br></br>
        -שעבר יותר מדי זמן,<br></br>
        -שאני תופס אותה בתקופה לא מתאימה בכלל,<br></br>
        -שהיא לא מבינה למה אני רוצה להיפגש איתה פנים אל מול פנים ולא יכול לספר את אשר על ליבי בשיחת טלפון,<br></br>
        -ובגדול שהיא לא מבינה מה אני רוצה ממנה."<br></br>

        לאחר שבעה חודשים, או מספר מדוייק שאינני זוכר, החלטתי להתקשר אליה<br></br>
        "למה עכשיו?" נשאלתי בטלפון, אוווו זו שאלה מעולה, זו שאלה באמת יפה. אבל לפעמים על מנת להיקל על מתן התשובה, אולי ניתן לשנות את השאלה, לכזאת פשוטה יותר, שאלה שלא תוביל למבוך בתגובה. אבל אולי, אולי אלו בדיוק השאלות שנשאלות ואמורות דווקא כן להישאל.<br></br>

        אני אגיע לתשובה לשאלה המעניינת הזאת בהמשך.<br></br>

        ואין טעם להמשיך מכאן לשם, ובחזרה ולפה, ולהזכיר את אשר היה שוב ושוב, אני זוכר פחות או יותר את אשר כתבתי, ומקווה שנכתב בצורה שבה גם אתם.<br></br>
        אך מבין הדברים הרבים שנכתבו, הדייט הראשון למעשה לא נכתב.<br></br>
        <br></br><span className='page'>עמוד 3</span><br></br><br></br>
        תתעורר על עצמך! יש לך עוד שעה דייט.<br></br>
        חצי שעה נסיעה, אני אחרי מקלחת, לכן נותרה ברשותי רבע שעה להתארגנות, בחישוב גיאומטרי וקטורי זריז, רבע שעה ספייר. סלאמתק, זה טוב לנו.<br></br>
        לא הסתבכתי בבחירת הבגדים, יש לי את חולצת המזל שלי, שאני שומר אך ורק לדייטים סופר חשובים ומיכנס ג'ינס סטנדרטי. ואת הנעליים הלבנות שלי, שאני נועל לכל מקום בערך. התחלתי וסיימתי שיחה טלפונית באורך חמש דקות עם חבר טוב, שבמהלכה נשאלתי, "האם אתה מתרגש?" ובאופן מאוד מפתיע לא, לא התרגשתי.<br></br>
        בעצם כן, כן התרגשתי, אבל זו הייתה הפעם ראשונה בחיי, שההתרגשות לוותה בתחושות שלווה ורוגע מוזרות עד מאוד.<br></br>
        עשר דקות ואני צריך לזוז, אוקיי.<br></br>
        שלפתי את החולצה מן הארון, התסכלתי עליה, היא התסכלה עליי בחזרה, נתתי לה מבט מסכם אחרון, והכנסתי אותה לתרדמת בחזרה למקום ממנה היא הגיעה. אני לא זקוק לה, אני לא צריך מזל הערב, חשבתי לעצמי.<br></br>
        אנחנו הולכים להיות חברים, זאת לא שאלה בכלל. אספתי חולצת טריקו  שחורה חלקה מקופלת מן הארון, הלבשתי את עצמי בה, הארון נסגר, מכנסיים, גרביים, חגורה, בלאגנים, גלגלתי סיגריה, עישנתי אותה, צחצחתי שיניים, אספתי בקבוק של מים ויצאתי לדרך.<br></br>

        נסיעה קלילה, המקום שנבחר על ידה היה באופן מפתיע סגור(היא פשוט השביעה אותי שהמקום סודי, והבטחתי לה לא לספר לאף אחד עליו), ונסענו לשבת בפאב לא רחוק כתחליף.<br></br>

        ברור שהיא תבוא עם המכנס שדיברנו עליו באחת מן ההתכתבויות בווטסאפ. איזו נסיכה. ישר קלטתי, הבחנתי, ברור שהבחנתי, לא היה ניתן שלא להבחין, לא היה ניתן שלא לבחון אותה מכף רגל ועד ראש. נסיכה, אומר לכם נסיכה.<br></br>
        <br></br><span className='page'>עמוד 4</span><br></br><br></br>
        היה לי כיף, הבנתי שעומדת מולי או יותר נכון יושבת מולי, מישהי נפלאה, שפשוט עטופה בשכבות כאלו ואחרות. רק צריך סבלנות. וזה הגיע, ברור שזה הגיע. הסבלנות השתלמה. שנים שאני מנסה לשכנע את אחיינית שלי שסבלנות לא קונים בשום חנות.<br></br>

        האופי שלה היה כה ניכר, שתחילתו הצליח לעבור בצורה כזאת או אחרת אף בשיחות שקדמו למפגש. אבל לא רק, היא מסוג האנשים, שמתוך התסכלות מעמיקה אל תוך תמונותיהם, היה ניתן להסיק דפוסים קלים של אופי ולהבחין בנקודות קטנות של קסם. נקודות הקסם האלו, הם שגרמו לי לזכור את כל הרגעים הקטנים איתה עד היום. וכמובן יופיה. באחד מן הבקרים, נסענו לבקר במשתלה, לא רחוקה מביתה. לאחר שכבר הגענו למקום והסתובבנו במשך כמה דק', ניגשתי לרכב להביא סווטשירט. בדרכי חזרה, לא מצאתי אותה, וחיפשתי אותה במשך כמה רגעים ספורים שהרגישו לי הרבה יותר מאשר מספר השניות שהם מכילות. ולבסוף לכשהתגלתה באחד מן השבילים מסתכלת אל אחד מן העציצים, בהיתי בה וחשבתי שהיא תמיד תוכל לדגמן פרחים אם בשלב כזה או אחר תחסר לה פרנסה.<br></br>
        היא בכלל גרמה לי לבהייה רבה, אבל לא רק בסיטואציות כאלו ואחרות, אלא גם בסיטואציות שהיו משמעותיות במיוחד עבורי. לראשנה כשפגשתי את הוריה בביתם, אמא שלה עמדה במטבח והכינה לי שניצלים, ואבא שלה ישב בסלון ופטפט איתי. זה היה אירוע כל כך מרגש, על גבול החלום, שהייתי חייב לבהות בשניהם כדי לחקוק אותו ולהיות בטוח שאיני חולם.<br></br>
        ואת הפעם ההיא בכנרת, שהיא החליטה שנכון להעיר אותי משנתי על ידי סחיטת בגד הים שלה מעל פניי. פתחתי את עיניי והסתכלתי עליה ללא הזזת שריר אחד בפניי, והיא? היא חייכה, אבל לא עד הסוף, כי החיוך שלה נשא ציפייה לתגובה ממני שלא הגיעה. לא הגבתי כי הייתי בהלם, זה מעשה כלכך שטותי ילדותי ומתוק, שפשוט לא יכולתי שלא ליפול בשוביו של המבט ובשוביה שלה. אז גם ברגע ההוא בהיתי.<br></br>
        <br></br><span className='page'>עמוד 5</span><br></br><br></br>

        זה פשוט שהיא לימדה אותי כה הרבה בזמן כה קצר, ולא רק דברים שטחיים, שלא ידעתי לפני, דברים כמו המשמעות של טופר, או איך בדיוק עובדת מערכת השמיעה, או מהן עונות ההמלטה של דגים בים האדום.<br></br>

        היא לימדה אותי שיש אחרת, שיש דברים שקיימים לא רק באגדות, שאומנם שום דבר לא מושלם או מתיימר להיות, אבל שיש סוג אהבה שלא מוותרים עליה. <br></br>
        אולי ארבעה חודשים יכולים להשמע תקופה לא ארוכה, אולם זאת אף פעם לא הייתה שאלה רק של זמן. השאלה היא כמה רגש נפרס על ידי האוהבים בסיפור באותו פרק הזמן. קחו את מספר הרגשות, תוסיפו את העוצמה, את הדאגה, את רגעי האושר, את המבט הנקי המשותף בעיניים, את החלומות, קחו את כל אלו.<br></br>
        תכפילו בשתיים.<br></br>
        קיבלנו המון.<br></br>
        ולאן כל זה נעלם אחרי הסוף?<br></br>
        אז גם זו שאלה מעולה, זה לא נעלם, זה פשוט משתנה ברגע למעגל של:<br></br>
        -איך אחרי כל מה שהיה שם, הצד השני מסוגל לקום ולוותר על הכל<br></br>
        ומכאן זה רק מעגל המכיל את עצמו, כאשר מלכתחילה אף אחד מתוך השניים בסיפור לא רצה להיות חלק ממנו ולהזין אותו.<br></br>
        וזה עצוב, זה פשוט מעגל עצוב.<br></br>
        <br></br><span className='page'>עמוד 6</span><br></br><br></br>

         האמנתי שעשינו מספיק מעשים טובים במרוצת השנים, שפשוט יום בהיר אחד כוח הבריאה החליט לפרגן לנו.<br></br>
        היינו אמורים לסיים את החיים האלו ביחד, אני מוכן להישבע כשסט סכינים מחודדות על גופי, שהרגשת את זה לפחות כמוני, וברוך שמו, הרגשתי. <br></br>
        את הגעת אליי כזאת מדהימה, בתקופה שבה אני הייתי כל כך לא.<br></br>
        עוד כמה דמעות שהתווספו להן לאגם שנצבר בשנה האחרונה. נו בסדר, כבר התרגלתי. גם כילד בכיתי, ואם הוא בכה, אז זה בסדר שגם אני. כי עד היום אני מעריך ואוהב את הילד שבי.<br></br>

        אני מצטער, אין לי איך לכתוב לך כמה שאני מצטער, לא רק על הסוף, על הכל. <br></br>
        על חוסר הבנת סדר העדיפויות.<br></br>
        על כל הרגעים שלא ביליתי בחברתך.<br></br>
        על חוסר הכנות שלי אל מול עצמי.<br></br>
        על זה שלא הייתי מספיק חכם וקשוב כדי להבין את כל זה, כדי להבין אותך, אותנו וגם אותי.<br></br>
        ובעיקר אני מצטער על הפחדים שבי. אני לא טוב איתם, שאלוהים יעזור לי כמה שאני לא טוב איתם. רק לו קיבלתי עוד חמש נקודות לאומץ, ולסיפור הזה היה סוף שונה, אני משוכנע.<br></br>

        לא הבנתי את כל מי ומה שהיית והיוות עבורי. היית אור, היית בית, היית העתיד השמח שלי.<br></br>
        בעצם את זה כן הבנתי, אבל רק מאוחר יותר, רק אחרי הסוף. <br></br>
        ואחרי הסוף? אחרי הסוף מה זה כבר משנה, זאת הייתה רק ההתחלה של התקופה הכי קשה בחיי.<br></br>
        נשאבתי לעצב, ללבד, ימים-שבועות-חודשים שבהם לא הצלחתי לתפקד, תקופה שבה ניסיתי לעשות הכל כדי לא לחשוב עלייך.<br></br>
        את כל החלומות שלי ניפצתי לעצמי בפנים במו ידי, וזה כאב, זה כאב כל כך.<br></br>
        <br></br><span className='page'>עמוד 7</span><br></br><br></br>

        לא עבר יום שלא רציתי לכתוב לך, שלא רציתי להרים לך טלפון, לפעמים רק על מנת לשמוע את קולך העדין, את הקול שכל כך אהבתי לשמוע. אבל לא יכולתי, לא הייתי מסוגל לתפקד באותה התקופה, הפסקתי את הלימודים ובנס לא פוטרתי מהעבודה. כמעט שלא יצאתי מהבית, אותו בית שכל יום הזכיר לי אותך, אותו בית שעברתי אליו כדי להישאר קרוב לקשר שלנו.<br></br>
        ולבסוף, אחרי חודשים של התייסרות, בשנייה שהצלחתי לגרד מעצמי חלק מהעצב הזה, לחשוף קווים של שפיות ולהדק את האופי קצת בחזרה, דיברתי איתך.<br></br>
        אני יודע שזה היה כבר מאוחר מדי, ויש יגידו שאף חצוף מדי.<br></br>
        לא התכוונתי לבקש ממך לחזור, רק רציתי לומר את כל מה שנכתב בפרק הזה,<br></br>
        רציתי לגרום לך להיזכר בחלק קטן מהרגעים הטובים, מספיק קטן כדי שיקל קצת על הסוף, כי לצערי לפעמים הסוף קובע, ולא הרוב.<br></br>


        ומה נותר לי, רק לאחל לה טוב, בריאות ושמחה, אבל לא איחול כזה של יום הולדת, וגם לא איחול שנוסחו כולל "באמת מכל הלב".<br></br>
        ואולי כלל אינו איחול הוא, אלא בכלל מחשבה, מעט אבסטרקטית ומעט מוחשית, שבה היא פשוט מחייכת, ואם היא מחייכת הרבה, אז היא שמחה, ואם היא שמחה, אז גם אני.<br></br>
        {/* <br></br> <br></br> */}
        {/* <img src={flower} style={{width:'100%',margin:'auto',display:'block',border:'10px solid #ACE894'}} alt="" /> */}
        <br></br><span className='page'>עמוד 8 ואחרון</span>
      </p>
    )
  },
  stm021234: {
    title: () => (
      <span class="title">
        <span class="title-p"> ראיון עבודה </span>
      </span>
    ),
    user: 'אנונימי',
    pages: '2 דקות',
    date: '24/12/23',
    content: () => (
      <> <p style={{ lineHeight: 0.8 }}><span style={{ fontSize: 72, fontWeight: 800 }}>א</span>נחנו מצטערים,
        אבל אתה טוב לנו מדי.</p>

        <p>-מז'תומרת?</p>
        <p>– תראה, אתה משקיע, לא מניאק… אנחנו רואים כאן שגם בפרידה האחרונה שלך "עשיתם את זה יפה". זה קצת
          מעבר
          למה שאנחנו מחפשים, אתה מבין?</p>
        <p>-אה, לא, לא ממש…</p>
        <p>-נו, אתה אומר את הדברים הנכונים, מכיל כזה ומתעצבן בערך פעם בשנה. אפילו לפגישה הזאת הגעת בזמן.</p>
        <p>-איך זאת בעיה? הייתי אמור לאחר?</p>
        <p>-התנהגות כזאת גורמת לנו לחשוב שאין פה אהה, נו, פגמים.</p>
        <p>-לא הבנתי.</p>
        <p>-דרמה, אנחנו צריכים דרמה.</p>
        <p>-אבל כתבתם שאתם מחפשים מישהו עם ניסיון, שזה לטווח ארוך, ושהתפקיד לא מיועד למי שעוד לא סיים ללמוד.
        </p>
        <p>-בסדר, זה משהו גנרי שאנחנו כותבים כדי לסנן מועמדים אחרים.</p>
        <p>-ובסוף אתם בוחרים אותם.</p>
        <p>-זה לא ככה, אתה סתם תוקפני עכשיו.</p>
        <p>-והבאתי ממליצים, זה לא נחשב?</p>
        <p>-כן נו, הבעיה היא לא שיש ממליצים. הבעיה היא שיש יותר מדי כאלה. זה חשוד מדי.</p>
        <p>-זה נראה כאילו אתם לא באמת מחפשים מועמדים.</p>
        <p>-לא לא, אנחנו דווקא מאוד רוצים לקלוט מישהו, אבל עוד לא החלטנו.</p>
        <p>-אז בשביל מה המודעה?</p>
        <p>-זה שוק תחרותי, אתה יודע. בימינו צריך להתכונן לכול תרחיש.</p>
        <p>-מה זה? מה אתם מסמנים שם?</p>
        <p>-מה? ה-OQ?</p>
        <p>-כן, זה. רגע, זה אומר שעברתי? שנכשלתי?</p>
        <p>-אממ, לא זה ולא זה.</p>
        <p>-מה?</p>
        <p>-אם אתה מתעקש אז אלו ראשי התיבות של אובר-קוואליפייד. אמרנו לך כבר, אתה טוב מדי בשבילנו.</p>
        <p>-תגידו, איך בכלל אפשר להיות אובר-קוואליפייד לתפקיד הזה?</p>
        <p>-אפשר, אפשר. אנחנו יודעים שיש הרבה טובים, אבל אתה כנראה מעל הרף. באמת שלא נוכל לקבל אותך ככה.</p>

        <p>-אבל אני רחוק מלהיות מושלם, אני גרוע ממש במלא דברים. איך אתם לא רואים את זה?</p>
        <p>-זה עניין של תחושה, קשה להסביר…</p>
        <p>-תנסו בכל זאת? כבר חרגנו מהפורמט הרגיל של הראיון הזה. בבקשה?</p>
        <p>-שמע, אתה יודע שאנחנו בוחנים התאמה לפי רשימת פרמטרים מורכבת, שנבנתה על סמך מחקר רב שנים וניסיון
          עם
          מועמדים קודמים…</p>
        <p>-אני רואה לאן זה הולך…</p>
        <p>-… ואתה עומד בכולם יפה מאוד. בחלק מהמקומות אפילו מצוין.</p>
        <p>-מה? לא יכול להיות שהכול טוב ובכל זאת לא טוב.</p>
        <p>-עובדה. תראה, המבדקים שבהם קיבלת ציון נמוך לא משפיעים ממש על הציון הכללי, הם שוליים ממש.</p>
        <p>-אז בעצם קורות החיים שלי בסדר גמור, אני עומד בכל הדרישות ובכל זאת אתם לא רוצים אותי.</p>
        <p>-איך נגיד לך את זה? אתה צודק, זה נכון. כן… תגיד, היית אצל המתחרים כבר? שמענו ששם דווקא מחפשים
          אנשים
          כמוך.</p></>
    )
  }
}