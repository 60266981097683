import { useParams } from 'react-router-dom';
import { articlesObject } from '../App'
import '../App.css'
import '../index.css';

export const Article = () => {
    const { id } = useParams();
    const article = articlesObject[id];
    if (!article) {
        return <span>כתבה לא נמצאה</span>
    }
    const isActive = id === 'msb010722';
    const { subtitle, pages, user, date, title, content } = article;
    return <>
        <div class="article-container">
            <div className={isActive ? 'title-container active' : 'title-container inactive'}>
                {title()}
                <div class="sub-title-container">
                    <span class="sub-title">
                        {subtitle && <span><i data-feather="hash" width="16px"></i>{subtitle}</span>}
                        <span><i data-feather="user" width="16px"></i>{user}</span>
                        <span><i data-feather="calendar" width="16px"></i>{date}</span>
                        <span><i data-feather="clock" width="16px"></i>{pages}</span>
                    </span>
                </div>
            </div>
            <div class="paragraphs">
                <div class="p1">
                    {content && content()}
                </div>
            </div>
            <div id="commento"></div>
        </div>
    </>
}