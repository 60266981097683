import {  useNavigate } from 'react-router-dom';
import './../App.css'
import '../index.css';

export const Home = () => {
    const navigate = useNavigate();

    return <>
        <div class="container">
            <div onClick={() => navigate(`/article/stm021234`)} class="column first"><span>ראיון עבודה</span></div>
            <div onClick={() => navigate(`/article/msb010722`)} class="column"><span>זאת שמעל לכל המצופה</span></div>
            <div class="column soon"><span>סתם כי רציתי לכתוב</span></div>
            <div class="column soon"><span>סתם כי רציתי לכתוב</span></div>
            <div class="column soon"><span>סתם כי רציתי לכתוב</span></div>
            <div class="column soon"></div>
            <div class="column soon"></div>
        </div>
     </>
}
